
import { defineComponent, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t, te } = useI18n();
    const i18n = useI18n();
    const translate = text => {
      return te(text) ? t(text) : text;
    };

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";

    const countries = {
      en: {
        flag: "/media/flags/united-states.svg",
        name: "English"
      },
      es: {
        flag: "/media/flags/spain.svg",
        name: "Spanish"
      },
      de: {
        flag: "/media/flags/germany.svg",
        name: "German"
      },
      ja: {
        flag: "/media/flags/japan.svg",
        name: "Japanese"
      },
      fr: {
        flag: "/media/flags/france.svg",
        name: "French"
      }
    };

    const setLang = lang => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = lang => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string()
        .min(4)
        .required(translate("email") + " " + translate("isRequired"))
        .email(translate("email") + " " + translate("validEmail"))
    });

    //Form submit function
    const onSubmitForgotPassword = values => {
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.FORGOT_PASSWORD, values)
          .then(() => {
            Swal.fire({
              text: translate("emailResetSend"),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: translate("okResetPass"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary"
              }
            }).then(function() {
              // Go to page after successfully login
              router.push({ name: "sign-in" });
            });
          })
          .catch(() => {
            // Alert then login failed
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger"
              }
            });
          });

        submitButton.value?.removeAttribute("data-kt-indicator");
      }, 2000);
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
      translate
    };
  }
});
